<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input id="title" class="input" type="text" v-model="post.title" />
            </div>
          </div>

          <div class="field">
            <label class="label">Slug</label>
            <div class="control">
              <input
                id="slug"
                class="input"
                type="text"
                v-model="post.slug"
                :disabled="post.published"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Blurb</label>
            <div class="control">
              <input id="blurb" class="input" type="text" v-model="post.blurb" />
            </div>
          </div>

          <div class="field">
            <label class="label">Image Name</label>
            <div class="control">
              <input
                id="image"
                class="input"
                type="text"
                v-model="post.image"
                placeholder="image.png"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Publish Date</label>
            <div class="control">
              <input
                id="publish_date"
                class="input"
                type="datetime-local"
                v-model="publish_date"
                :disabled="post.published"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Content</label>
            <div class="control">
              <textarea id="content" class="textarea" rows="10" v-model="post.content"></textarea>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <label
                v-for="category of categories"
                :key="category.name"
                class="checkbox"
                :for="category.slug"
              >
                <input
                  class="ml-3"
                  type="checkbox"
                  :id="category.slug"
                  :value="category.name"
                  v-model="post.categories"
                />
                {{ category.name }}
              </label>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="buttons">
              <button class="button is-primary" @click="savePost(false)">
                Save
              </button>

              <button v-if="!post.published" class="button is-primary" @click="savePost(true)">
                Save - Publish
              </button>

              <a href="/" class="button">Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { posts, aggregates, categories, fieldValue } from '../firebase';
import { updateLatestPosts, updatePopularPosts } from '@/helpers';

export default {
  name: 'Index',
  data() {
    return {
      post: {},
      categories: [],
      publish_date: new Date(),
    };
  },
  methods: {
    savePost: async function(publish) {
      if (publish) this.post.published = true;

      this.post.publish_date = new Date(this.publish_date);
      this.post.updated_date = new Date();
      try {
        await posts.doc(this.postID).set(this.post, { merge: true });
        alert('Saved!');
      } catch (error) {
        console.error(error);
      }

      this.updatePostCategories();
      updateLatestPosts();
      updatePopularPosts();
      this.clearPostCache();
    },
    updatePostCategories: async function() {
      try {
        const snapshot = await categories.get();
        snapshot.forEach(async (doc) => {
          if (this.post.categories.includes(doc.data().name)) {
            await doc.ref.update({
              posts: fieldValue.arrayUnion(this.postID),
            });
          } else {
            await doc.ref.update({
              posts: fieldValue.arrayRemove(this.postID),
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    clearPostCache: async function() {
      const options = {
        method: 'PURGE',
        redirect: 'follow',
      };

      try {
        await fetch(`https://sliceofkeesh.com/post/${this.post.slug}`, options);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created: async function() {
    this.postID = window.location.pathname.split('/')[2];
    const doc = await posts.doc(this.postID).get();
    this.post = doc.data();
    this.publish_date = new Date(
      this.post.publish_date
        .toDate()
        .toString()
        .split('GMT')[0] + ' UTC'
    )
      .toISOString()
      .split('.')[0];

    let categories = await aggregates.doc('category_list').get();
    this.categories = categories.data().categories;
  },
};
</script>
